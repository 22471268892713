import React from 'react';
import styles from './index.module.less';
import classNames from 'classnames';

const LoadingIcon: React.FC = () => {
  return (
    <div className={styles.loadingIcon}>
      <div className={classNames(styles.dot, styles.oDot)} />
      <div className={classNames(styles.dot, styles.bDot)} />
    </div>
  );
};

const Loading: React.FC<{ content?: string }> = ({ content = 'Loading...' }) => {
  return (
    <div id="toastLoading" className={styles.loading}>
      <LoadingIcon />
      <p className={styles.desc}>{content}</p>
    </div>
  );
};

export default Loading;
